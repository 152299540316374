import Cookies from './Cookies'
import { useEffect, useState } from 'react'

const cookieEmailName = 'mt_email'
var cookieEmailValue = null
var callbacks = []
var isInit = false

export const useMarketing = function() {
  const [isRegistered, setIsRegistered] = useState(cookieEmailValue != null)
  useEffect(() => {
    if (isInit) return
    const cb = function() {
      setIsRegistered(cookieEmailValue != null)
    }
    callbacks.push(cb)
    return () => {
      const index = callbacks.indexOf(cb)
      if (index !== -1) callbacks.splice(index, 1)
    }
  }, [])
  return { isRegistered }
}


export const initialize = function() {
  const value = Cookies.getItem(cookieEmailName)
  if (value) {
    cookieEmailValue = new Date(parseInt(value))
    callbacks.forEach(f => f())
    callbacks = []
  }
  isInit = true
}

export const setRegistered = function() {
  cookieEmailValue = new Date() 
  Cookies.setItem(cookieEmailName, cookieEmailValue.getTime())
  callbacks.forEach(f => f())
  callbacks = []
}

import React, { useState, useEffect, useLayoutEffect, createContext, useContext } from "react"
import Menu from "../sections/Menu"
import Footer from "../sections/Footer"
import { Toast } from "../sections/Toast"
import { Modal } from "../sections/Modal"
import { Banner } from "../sections/Banner"
import { PopupComponent as Popup } from "./Popup"
import { GeoLocationComponent as GeoLocation } from "./GeoLocation"
import { useClasses, processClasses } from '../lib/ReactUtils'
import { useLocation } from "@reach/router"
import { random } from '../lib/Utils'
import Blob1 from '../svg/Blob1'
import Blob2 from '../svg/Blob2'
import Blob3 from '../svg/Blob3'
import Blob4 from '../svg/Blob4'
import Blob5 from '../svg/Blob5'
import Blob6 from '../svg/Blob6'
import Blob7 from '../svg/Blob7'
import '../styles/Main'
import '../styles/Forms'

const COLORS = ['blue','orange','yellow','indigo','red','purple','green', 'cyan', 'teal', 'amber', 'pink', 'lime']


// we need to hide startup artifacts without waiting for external css to load
const fadeInCss = `
  @keyframes fade-in {0% {opacity: 0;} 75% {opacity: 0;} 100% {opacity: 1;}}
  .fade-in { animation: fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both; }
`
processClasses('fade-in min-h-120 relative mt-24 sm:mt-32 mb-12 w-full container')

var initState = {menu:true, full:false, short:false, dark: false}
export const LayoutContext = createContext(initState)
var firstRun = true
var setter


export function LayoutProvider({children}) {
  const [state, setState] = useState(initState)
  if (firstRun) {
    firstRun = false
    setter = setState
  }
  return (
    <LayoutContext.Provider value={state}>
      {children}
    </LayoutContext.Provider>
  )
}


export function LayoutWrapper({ children }) {
  const { pathname } = useLocation()
  const { dark, full, short, menu } = useContext(LayoutContext)
  const [isClient, setClient] = useState(false)

  useEffect(() => {
    setClient(true)
  }, [])

  ///////////////////// blobs //////////////////////

  const blobs = useClasses()
  const [x1, setX1] = useState(1)
  const [x2, setX2] = useState(2)
  const [x3, setX3] = useState(3)
  const [x4, setX4] = useState(4)
  const [x5, setX5] = useState(5)
  const [x6, setX6] = useState(6)
  const [x7, setX7] = useState(7)
  const [color1, setColor1] = useState(COLORS[0])
  const [color2, setColor2] = useState(COLORS[1])
  const [color3, setColor3] = useState(COLORS[2])
  const [color4, setColor4] = useState(COLORS[3])
  const [color5, setColor5] = useState(COLORS[4])
  const [color6, setColor6] = useState(COLORS[5])
  const [color7, setColor7] = useState(COLORS[6])

  useLayoutEffect(() => {
    setX1(random(3))
    setX2(random(3))
    setX3(random(3))
    setX4(random(3))
    setX5(random(3))
    setX6(random(3))
    setX7(random(3))
    setColor1(COLORS[random(12)])
    setColor2(COLORS[random(12)])
    setColor3(COLORS[random(12)])
    setColor4(COLORS[random(12)])
    setColor5(COLORS[random(12)])
    setColor6(COLORS[random(12)])
    setColor7(COLORS[random(12)])
    // Mautic tracker
    if (typeof window != 'undefined' && window.mt) window.mt('send', 'pageview')
  }, [pathname])

  ////////////////////// end of blobs //////////////////////


  // console.log('rehydrated')
  if (isClient) return (
    <>
      <style dangerouslySetInnerHTML={{__html:fadeInCss}}/>
      <GeoLocation/>
      { menu ? <Menu dark={dark} className='fade-in'/> : null }
      <Banner />
      <div className={'fade-in min-h-120 relative w-full' + (full ? '' : ' container') + (menu ? ' mt-24 sm:mt-32 mb-12' : ' h-full')}>
        <div ref={blobs}>
          {menu ?
            <>
              { short ?
                <>
                  <Blob1 className={'w-80 h-80 absolute text-'+color1+'-500 opacity-5 top-1/3 pointer-events-none left-'+x1+'/12'}/>
                  <Blob2 className={'w-90 h-90 absolute text-'+color2+'-500 opacity-5 top-2/3 pointer-events-none right-'+x2+'/12'}/>
                </> :
                <>
                  <Blob1 className={'w-80 h-80 absolute text-'+color1+'-500 opacity-5 top-1/8 pointer-events-none left-'+x1+'/12'}/>
                  <Blob2 className={'w-90 h-90 absolute text-'+color2+'-500 opacity-5 top-2/8 pointer-events-none right-'+x2+'/12'}/>
                  <Blob3 className={'w-90 h-90 absolute text-'+color3+'-500 opacity-5 top-3/8 pointer-events-none left-'+x3+'/12'}/>
                  <Blob4 className={'w-80 h-80 absolute text-'+color4+'-500 opacity-5 top-4/8 pointer-events-none right-'+x4+'/12'}/>
                  <Blob5 className={'w-80 h-80 absolute text-'+color5+'-500 opacity-5 top-5/8 pointer-events-none left-'+x5+'/12'}/>
                  <Blob6 className={'w-80 h-80 absolute text-'+color6+'-500 opacity-5 top-6/8 pointer-events-none right-'+x6+'/12'}/>
                  <Blob7 className={'w-90 h-90 absolute text-'+color7+'-500 opacity-5 top-7/8 pointer-events-none left-'+x7+'/12'}/>
                </>
              }
            </> : '' 
          } 
        </div>
        {children}
      </div>
      { menu ? <Footer className='fade-in'/> : null }
      <Toast />
      <Popup />
      <Modal />
    </>
  )

  return (
    <>
      <style dangerouslySetInnerHTML={{__html:fadeInCss}}/>
      { menu ? <Menu className='fade-in'/> : null }
      <div className='fade-in min-h-120 relative mt-24 sm:mt-32 mb-12 w-full container'>
        {children}
      </div>
      <Footer className='fade-in'/>
    </>
  )


}

export default function Layout({dark = false, full = false, short = false, menu = true, children}) {
  initState = {dark, full, short, menu}
  useEffect(() => setter({dark, full, short, menu}), [dark, full, short, menu])
  return children
}

import React, { useState, useEffect } from 'react'
import { useClasses } from '../lib/ReactUtils'

/* https://www.tailwindtoolbox.com/components/alerts */
const instance = {}
// if (typeof window !== 'undefined') window.banner = instance

const COLORS = {
  red: 'bg-red-400',
  blue: 'bg-blue-300',
  green: 'bg-green-500',
  gray: 'bg-gray-400'
}

export function Banner() {
  const root = useClasses()
  const [banner, setBanner] = useState(null)
  const [color, setColor] = useState('red')
  const [hidden, setHidden] = useState(true)
  useEffect(() => {
    let timer
    instance.setBanner = (value, color='red') => {
      if (value) setHidden(false)
      setColor(color)
      setBanner(value)
    }
    instance.setTimedBanner = (value, delay = 5000, color='red') => {
      if (value) setHidden(false)
      setBanner(value)
      setColor(color)
      timer = setTimeout(setBanner, delay)
    }
    instance.hide = () => setBanner(null)
    return () => clearTimeout(timer)
  }, [])

  return (
    <div ref={root} className={"w-screen fixed top-0 z-50 " + (hidden ? "hidden" : (banner ? "slide-in-top-banner" : "slide-out-top-banner"))} onClick={() => setBanner(null)}>
      <div className={"cursor-pointer w-full flex items-center px-4 py-2 shadow text-white " + COLORS[color]}>
        <span className="w-full text-center">{banner}</span>
        <svg className="fill-current text-white absolute right-2" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
          <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
        </svg>
      </div>
    </div>
  )
}

export function useBanner() {
  return instance
}

import React from 'react'

export default function({className = ''}) {
  return (
    <svg width="600" height="600" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="m403.9 204.8c36.5 27.8 75.8 61.5 87.7 107.1 11.9 45.6-3.6 103.1-40.1 138.3s-94 48-156.9 53.4c-63 5.4-131.3 3.4-178-31.7-46.6-35.2-71.6-103.5-50.1-150.3 21.6-46.8 89.7-72 136.4-99.9 46.7-27.8 71.9-58.3 101.3-62.5 29.5-4.2 63.2 17.7 99.7 45.6z"
        fill="currentColor"
      />
    </svg>
  )
}
import React from "react"
import { useClasses } from '../lib/ReactUtils'

const SIZES = {
  sm: 'w-4 h-4',
  md: 'w-6 h-6',
  lg: 'w-10 h-10',
  xl: 'w-16 h-16',
  '2xl':'w-20 h-20',
  '3xl':'w-30 h-30',
}

const STROKES = {
  sm: '4',
  md: '4',
  lg: '3',
  xl: '2',
  '2xl': '2',
  '3xl': '1.5'
}

export default function Loader({ className = 'text-white', size = 'md', centered }) {
  const root = useClasses()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 38 38"
      className={className + ' ' + SIZES[size] + (centered ? ' mx-auto' : '')}
      ref={root}
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeWidth={STROKES[size]}
      >
        <circle cx="19" cy="19" r="16.5" strokeOpacity="0.5" />
        <path d="M 35.5,19 C 35.5,9.8883334 28.111667,2.5 19,2.5">
          <animateTransform
            attributeName="transform"
            dur="0.9s"
            from="0 19 19"
            repeatCount="indefinite"
            to="360 19 19"
            type="rotate"
          />
        </path>
      </g>
    </svg>
  )
}

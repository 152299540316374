import React from 'react'

export default function({className = ''}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="600" height="600" viewBox="0 0 600 600" className={className}>
      <path
        d="m 476.2,193.1 c 32.5,61.4 25.6,140.4 -10.4,206.3 C 429.9,465.3 365,518.2 290.8,523.5 216.6,528.8 133.1,486.7 98.4,421.4 63.6,356.2 77.4,267.8 119.1,201.1 160.9,134.3 230.4,89.2 301.2,88.5 c 70.7,-0.7 142.6,43.2 175,104.6 z"
        fill="currentColor"
      />
    </svg>
  )
}
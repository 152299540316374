import { globalHistory } from '@reach/router'


const _isBrowser = typeof window != 'undefined'
export const isBrowser = () => _isBrowser

export const isMobile = () =>
    // eslint-disable-next-line
    (typeof navigator != 'undefined' && navigator.userAgent && navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i)) ||
    // eslint-disable-next-line
    (_isBrowser && window.innerWidth < 736)

const DATE_OPTIONS = { year: 'numeric', month: 'long', day: 'numeric'}
export const formatDate = function(dateString) { return (new Date(dateString).toLocaleDateString("en-US", DATE_OPTIONS)) }



// https://stackoverflow.com/questions/19669786/check-if-element-is-visible-in-dom
export const isVisible = function(elem) {
  if (!elem) return console.error('isVisible: No elem provided')
  if (!(elem instanceof Element)) return console.error('isVisible: elem is not an element')

  const style = getComputedStyle(elem)
  if (style.display === 'none' || style.visibility !== 'visible' || style.opacity < 0.1) return false

  const bounds = elem.getBoundingClientRect()
  if (elem.offsetWidth + elem.offsetHeight + bounds.height + bounds.width === 0) return false

  const elemCenterY = bounds.top + elem.offsetHeight / 2
  if (elemCenterY < 0) return false
  if (elemCenterY > (document.documentElement.clientHeight || window.innerHeight)) return false

  const elemCenterX = bounds.left + elem.offsetWidth / 2
  if (elemCenterX < 0) return false
  if (elemCenterX > (document.documentElement.clientWidth || window.innerWidth)) return false

  let pointContainer = document.elementFromPoint(elemCenterX, elemCenterY)
  do {
      if (pointContainer === elem) return true
      pointContainer = pointContainer.parentNode
  } while (pointContainer)
  return false
}

// taken from meteor app
export const hasQuery = function() {
  if (!_isBrowser) return false
  return globalHistory.location.href.indexOf('?') !== -1
}

export const getQuery = function() {
  if (!_isBrowser) return ''
  const url = globalHistory.location.href
  const index = url.indexOf('?')
  if (index === -1) return ''
  return url.substr(index)
}

export const random = function(mul, ignores) {
  if (ignores) {
    var result
    do {
      result = Math.floor(Math.random()*mul)
    }
    while (ignores.includes(result))
    return result
  }
  return Math.floor(Math.random()*mul)
}

export const toUpperCaseFirst = function(str) {
  return str[0].toUpperCase() + str.substr(1)
}

export const formatNumber = function(num) {
  num = 1.0 * num
  var suffix = ''
  if (num < 1000) return '' + num
  if (num > 1000000) {
    num = Math.round( num / 1e4) / 100
    return num + 'M'
  }
  const thousands = Math.floor(num/1000)
  var units = Math.round(num - thousands*1000) + ''
  while (units.length < 3) units = '0' + units
  return thousands + ',' + units + suffix
}


// https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea")
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = "0"
  textArea.style.left = "0"
  textArea.style.position = "fixed"

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    var successful = document.execCommand('copy')
    if (!successful) console.error('copyToClipboard: fallback failed')
  }
  catch (err) {
    console.error('copyToClipboard: fallback copy failed ', err)
  }

  document.body.removeChild(textArea);
}

export function copyToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(function() {
    // console.log('Async: Copying to clipboard was successful!')
  }, function(err) {
    console.error('copyToClipboard: async copy failed ', err)
  })
}

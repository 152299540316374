// https://stackoverflow.com/questions/14573223/set-cookie-and-get-cookie-with-javascript
const Cookies = { }
const cache = { }
const bSecure = `${process.env.NODE_ENV}` == 'production'
const sDomain = `${process.env.DOMAIN}`
// console.log('domain ' + sDomain)

if (typeof document == 'undefined') {
  Cookies.getItem = Cookies.setItem = Cookies.removeItem = function() { }
  Cookies.hasItem = function() { return false }
  Cookies.keys = function() { return [] }
}
else {
  Cookies.getItem = function(sKey) {
    var sValue = cache[sKey]
    if (sValue) return sValue
    cache[sKey] = sValue = decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[-.+*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null
    return sValue
  }

  Cookies.setItem = function(sKey, sValue, sPath, vEnd) {
    if (!sKey || /^(?:expires|max-age|path|domain|secure)$/i.test(sKey)) return false
    var sExpires = ''
    // default is a year
    if (!vEnd) {
      vEnd = new Date()
      vEnd.setFullYear(vEnd.getFullYear() + 1)
    }
    switch (vEnd.constructor) {
      case Number:
        sExpires = vEnd === Infinity ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT' : '; max-age=' + vEnd
        break
      case String:
        sExpires = '; expires=' + vEnd
        break
      case Date:
        sExpires = '; expires=' + vEnd.toUTCString()
        break
      default:
        break
    }
    document.cookie = encodeURIComponent(sKey) + '=' + encodeURIComponent(sValue) + sExpires + (sDomain ? '; domain=' + sDomain : '') + '; path=' + (sPath || '/') + (bSecure ? '; secure' : '')
    cache[sKey] = sValue
    return true
  }

  Cookies.removeItem = function (sKey, sPath) {
    if (!sKey || !this.hasItem(sKey)) return false
    delete cache[sKey]
    document.cookie = encodeURIComponent(sKey) + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' + ( sDomain ? '; domain=' + sDomain : '') + '; path=' + (sPath || '/')
    return true
  }

  Cookies.hasItem = function (sKey) {
    return cache[sKey] != null || (new RegExp('(?:^|;\\s*)' + encodeURIComponent(sKey).replace(/[-.+*]/g, '\\$&') + '\\s*\\=')).test(document.cookie)
  }

  Cookies.keys = function () {
    var aKeys = document.cookie.replace(/((?:^|\s*;)[^=]+)(?=;|$)|^\s*|\s*(?:=[^;]*)?(?:\1|$)/g, '').split(/;/) 
    for (var nIdx = 0; nIdx < aKeys.length; nIdx++) { aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]); }
    return aKeys
  }
}

export default Cookies
// if (typeof window != 'undefined') window.Cookies = Cookies

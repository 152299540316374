import React, { useState } from 'react'
import { useClasses } from '../lib/ReactUtils'
import Loader from './Loader'
import PostNewsletter from '../lib/PostNewsletter'
import { useToast } from '../sections/Toast'

export default function NewsletterForm({className = ''}) {
  const root = useClasses()
  const toast = useToast()
  const [errors, setErrors] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const [result, setResult] = useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault()
    const _errors = []
    const target = e.currentTarget
    const name = target[0].value.trim().replace(/\s+/g,' ')
    const email = target[1].value.replace(/\s/g,'')
    if (name.length == 0 && email.length == 0) _errors.push('Please enter your info')
    else if (name.length == 0) _errors.push('Please enter your name')
    else if (name.length < 5) _errors.push('Name too short')
    else if (email.length == 0) _errors.push('Please enter your email')
    else if (email.length < 5 || !email.includes('@')) _errors.push('Invalid email')
    setErrors(_errors)
    setResult(null)
    if (_errors.length) return
    setSubmitting(true)
    const array = name.split(' ')
    const first = array.shift()
    const last = array.join(' ')
    const result = await PostNewsletter({first, last, email})
    setSubmitting(false)
    if (result) toast.show('Success! Get ready for great articles', 'success')
    else toast.show('Failed! Please email us at info@oneoffice.ca', 'error')
    setResult(result)
  }

  return (
    <div ref={root} className={className}>
      <div className='max-w-screen-xl mx-auto'>
        <div className='flex justify-center flex-col'>
          <div className='flex items-center flex-row gap-x-3'>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
            <div className='text-white'>
              <div className='text-lg font-bold text-left leading-none'>Newsletter</div>
              <div className='text-xs md:text-sm font-medium max-w-sm mt-1 text-left'>Get the latest in thought leadership</div>
            </div>
          </div>
          <div className='mt-6 w-full lg:w-auto'>
            { errors.map((e) => <div className='text-xs mb-1 error'>{e}</div>) }
            { result === true ? <div className='text-green-500 font-normal text-xs mb-1 leading-snug'>Success! Get ready for great articles</div> : null }
            { result === false ? <div className='text-green-500 font-normal text-xs mb-1 leading-snug'>Failed! Please email us at info@oneoffice.ca</div> : null }
            <form className='basic text-sm w-full mx-auto flex flex-col gap-y-2 lg:flex-row' onSubmit={handleSubmit}>
              <div className='relative w-full flex-4'>
                <input className='basic w-full pl-3 py-3 border border-blue-400 lg:border-r-0 rounded lg:rounded-r-none bg-primary-dark text-white font-normal text-xs text-center lg:text-left' name='name' type='text' placeholder=' ' disabled={submitting}/>
                <label htmlFor='name' className='w-full text-white font-light tracking-wider text-center lg:text-left'>Name</label>
              </div>
              <div className='relative w-full flex-5'>
                <input className='basic w-full pl-3 py-3 border border-blue-400 rounded lg:rounded-none bg-primary text-white font-normal text-xs text-center lg:text-left' name='email' type='email' placeholder=' ' disabled={submitting}/>
                <label htmlFor='newsletter' className='w-full text-white font-light tracking-wider text-center lg:text-left'>Email</label>
              </div>
              <button className='flex-2 basic w-full py-3 lg:border-r-0 px-1 rounded lg:rounded-l-none font-bold bg-orange-500 leading-tight text-white hover:bg-orange-800' type='submit'>
                {submitting ? <Loader size='sm' className='inline'/> : 'Subscribe'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import { LayoutWrapper, LayoutProvider } from './src/components/Layout'
// import { initChat } from './src/lib/Chat'
// import { initialize as initializeMarketing } from './src/lib/Marketing'


export function wrapPageElement({ element, props }) {
  return (
    <LayoutProvider>
      <LayoutWrapper {...props}>{element}</LayoutWrapper>
    </LayoutProvider>
  )
}

/*
function loadBrowser() {
  window.removeEventListener('scroll', loadBrowser)
  window.removeEventListener('click', loadBrowser)

  // mautic
  import('./src/lib/Mautic.js')
  // kick off cookie detection
  initializeMarketing()
  // chat
  // initChat()
}

// hack to prevent marketing from being loaded by bots and pagespeed insights
window.addEventListener('scroll', loadBrowser)
window.addEventListener('click', loadBrowser)
*/


import React, { useState, useRef, useEffect, useLayoutEffect, useCallback, forwardRef } from 'react'
import { createPopper } from '@popperjs/core';
import { useClasses } from './ReactUtils'


/**
 * Poppers rely on the 3rd party library [Popper.js](https://popper.js.org/docs/v1/) for positioning.
 */
const Popper = forwardRef(function Popper(props, tooltipRef) {
  const {
    anchorEl,
    children,
    open:_open,
    placement,
    closeDelay = 500,
    offset = 5,
    ...other
  } = props

  const timer = useRef()
  const popperRef = useRef(null)
  const [open, setOpen] = useState(_open)
  useClasses(tooltipRef)

  useLayoutEffect(() => {
    if (popperRef.current) popperRef.current.update()
  })

  const handleOpen = useCallback(() => {
    if (!tooltipRef.current || !anchorEl || !open) return
    if (popperRef.current) popperRef.current.destroy()

    if (anchorEl && anchorEl.nodeType === 1) {
      const box = anchorEl.getBoundingClientRect()
      if (box.top === 0 && box.left === 0 && box.right === 0 && box.bottom === 0) {
        // likely the anchor disapeared from DOM
        return setOpen(false)
      }
    }
    else {
      return setOpen(false)
    }

    popperRef.current = createPopper(anchorEl, tooltipRef.current, {
      placement,
      modifiers: [
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['top', 'bottom'],
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, offset],
          },
        },
      ],
    })
    setTimeout(popperRef.current.update, 0)

  }, [anchorEl, open])

  const handleRef = useCallback(
    (node) => {
      tooltipRef.current = node
      handleOpen()
    },
    [tooltipRef, handleOpen]
  )

  const handleClose = () => {
    if (timer.current) clearTimeout(timer.current)
    timer.current = null
    setOpen(false)
    if (popperRef.current) popperRef.current.destroy()
  }

  useEffect(() => handleClose, [])

  useEffect(() => { if (open) handleOpen() }, [open])

  useEffect(() => {
    if (_open && !open) {
      if (timer.current) {
        clearTimeout(timer.current)
        timer.current = null
      }
      setOpen(true)
    }
    else if (!_open && open) {
      if (!anchorEl) {
        handleClose()
      }
      else {
        if (timer.current) clearTimeout(timer.current)
        timer.current = setTimeout(handleClose, closeDelay)
      }
    }
  }, [_open, open, anchorEl])

  if (!open) return null

  return (
    <div ref={handleRef} role="tooltip" {...other}>
      {children}
    </div>
  )
})

export default Popper

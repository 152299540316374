import React from 'react'

export default function({className = ''}) {
  return (
    <svg version="1.1" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        fill="currentColor"
        d="m429.9 375c-43.3 75-216.5 75-259.8 0s43.3-225 129.9-225 173.2 150 129.9 225z"
      />
    </svg>
  )
}
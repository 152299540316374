import React from 'react'

export default function({className = ''}) {
  return (
    <svg width="600" height="600" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="m386.4 174.3c22.5 23.2 35.6 52.5 63.2 88.6 27.6 36.2 69.8 79.1 74 123.2 4.2 44.2-29.4 89.5-73 114.6-43.6 25.2-97.1 30.3-145.4 23.1-48.3-7.1-91.4-26.5-114.7-58.2-23.3-31.8-26.8-76-29.3-113.5-2.6-37.5-4.2-68.3-8.2-113.5s-10.5-104.7 13.6-127.4 78.7-8.4 122 7.2c43.3 15.6 75.3 32.7 97.8 55.9z"
        fill="currentColor"
      />
    </svg>
  )
}
import "@fontsource/poppins/300.css" // light
import "@fontsource/poppins/400.css" // normal
import "@fontsource/poppins/600.css" // semibold
import "@fontsource/poppins/700.css" // bold
import "@fontsource/poppins/800.css" // extrabold
// import "@fontsource/poppins/900.css" // black
import '../css/animations.css'
import { addCss, addClass, configure } from '../lib/ReactUtils'

configure({
  colors: {
    primary: '009cfd',
    'primary-dark': '2b6cb0',
    highlight: 'ed8936'
  }
})

addClass('main', 'relative flex flex-col')
addClass('heading-separator', 'w-20 h-2 bg-primary-dark mt-16 mb-10')
addClass('title', 'text-4xl sm:text-5xl lg:text-6xl font-bold text-primary-dark leading-tight')
addClass('subtitle', 'text-2xl sm:text-3xl lg:text-4xl mb-4 font-light tracking-tightest leading-tight text-primary')
addClass('description', 'mt-3 text-left text-lg font-light tracking-snug text-gray-800')
addClass('menu', 'cursor-pointer transition duration-200 px-2 ml-3 mb-2 lg:pb-1 lg:mb-0 hover:text-primary border-l-5 lg:border-l-0 lg:border-b-4 border-transparent')
addClass('menu-dark', 'text-white hover:text-orange-500')
addClass('error', 'text-red-500 text-xs m-1')
addClass('info', 'text-blue-800 text-sm m-1')
addClass('subheading', 'font-bold text-primary text-left uppercase sm:text-lg xl:text-xl')
addClass('heading', 'font-extrabold text-primary-dark tracking-wide mt-2 text-left text-2xl sm:text-3xl lg:text-4xl leading-tight')
addClass('divider', 'w-full border-t-2 border-gray-300')
addCss(`
  .button {
    @apply uppercase transition duration-300 py-3 px-4 rounded-lg bg-primary text-white text-md hover:bg-primary-dark cursor-pointer text-center
  }
  .button.tight {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    @apply leading-tight
  }
  .menu.active {
    @apply border-primary text-primary-dark
  }
  .menu-dark.active {
    @apply border-orange-500 text-orange-500
  }
  .button.lg {
    @apply py-4 sm:py-5 px-4 sm:px-6 text-xl sm:text-2xl sm:font-semibold
  }
  .button.sm {
    @apply leading-none py-0 px-1 sm:px-2 text-base sm:text-lg
  }
  .button.cta {
    @apply bg-orange-500 text-white hover:bg-orange-300 hover:text-orange-800
  }
  .button.cta:disabled {
    @apply opacity-50 hover:bg-orange-500 hover:text-white cursor-default
  }
  .button.dark {
    @apply bg-primary-dark text-white hover:bg-blue-900
  }
  .button.transparent {
    @apply bg-transparent border-2 border-primary text-primary hover:bg-primary hover:text-white
  }
  a {
    @apply transition duration-200 cursor-pointer focus:outline-none
  }
  a:not(.basic):not(.menu):not(.button):not(.footer):not(.dark), .link {
    @apply text-primary
  }
  a:not(.basic):not(.menu):not(.button):not(.footer):not(.dark):hover, .link:hover {
    @apply text-primary-dark
  }
  a.footer {
    @apply hover:text-primary text-gray-300 text-lg
  }
  .description p:not(.quote) {
    @apply mt-3;
  }
  #footer {
    @apply bg-gradient-to-br from-slate-900 to-slate-700
  }
  .container {
    @apply mx-auto px-2
  }
  .quote {
    @apply py-4 pl-3 pr-2 border-l-4 border-primary bg-blue-100 my-5 text-primary-dark font-semibold
  }
  .quote.warning {
    @apply border-yellow-600 bg-yellow-200 text-yellow-900
  }
  .quote.error {
    @apply border-red-600 bg-red-200 text-red-800
  }
`)


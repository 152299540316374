import React, { useState, useEffect } from 'react'
import { useClasses } from '../lib/ReactUtils'

// https://www.creative-tim.com/learning-lab/tailwind-starter-kit/documentation/react/actives/regular
const instance = { }

export function Modal() {
  const [active, setActive] = useState(false)
  const [hidden, setHidden] = useState(true)
  const [modal, setModal] = useState({})
  const root = useClasses()
  const bg = useClasses()
  useEffect(() => {
    instance.show = (value) => {
      setHidden(false)
      setActive(true)
      setModal(value)
    }
    instance.hide = () => {
      setActive(false)
      setTimeout(() => {
        if (active) return
        setModal({})
        setHidden(true)
      }, 500)
    }
  }, [])

  const Component = modal?.component
  const data = modal?.data || { }

  return (
    <>
      <div
        className={ "justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none " + (hidden ? "hidden" : "z-50")}
        onClick={modal?.keepOpen ? undefined : instance.hide}
        ref={root}
      >
        <div className={"active relative w-auto my-6 mx-auto px-3 md:px-0 min-w-1/2 " + (hidden ? "hidden" : (active ? "fade-in-fwd" : "fade-out-bck"))} onClick={(e) => e.stopPropagation()}>
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            {modal?.title ?
              <div className="flex items-start justify-between p-3 gap-x-8 border-b border-solid border-gray-300 rounded-t">
                <h3 className="text-3xl font-semibold">
                  {modal.title}
                </h3>
                <svg onClick={instance.hide} className="ml-auto -mr-1 fill-current text-black hover:text-primary duration-200 transition cursor-pointer w-6 h-6" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                </svg>
              </div> : 
              <button className='rounded-full z-10 bg-white text-black opacity-70 p-1 absolute -top-3 -right-3 hover:bg-primary hover:text-white transition duration-200' onClick={instance.hide}>
                <svg className="fill-current cursor-pointer w-6 h-6" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                </svg>
              </button>
            }
            {modal?.body ?
              <>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-gray-600 text-lg leading-relaxed">
                    {modal.body}
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-3 border-t border-solid border-gray-300 rounded-b">
                  <button
                    className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none"
                    type="button"
                    style={{ transition: "all .15s ease" }}
                    onClick={instance.hide}
                  >
                    OK
                  </button>
                </div>
              </>
              : null
            }
            { Component && 
              <div className={'p-4 sm:p-6 md:p-8' + (modal.className ? ' ' + modal.className : '')}>
                <Component ref={modal.ref} {...data}/>
              </div>
            }
          </div>
        </div>
      </div>
      <div className={"active fixed inset-0 z-40 " + (hidden ? "hidden" : "bg-black z-40 " + (active ? "fade-in-25" : "fade-out-25"))} ref={bg}></div>
    </>
  )
}


export function useModal() {
  return instance
}

if (typeof window !== 'undefined') window.modal = instance

import React from 'react'

export default function({className = ''}) {
  return (
    <svg width="600" height="600" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="m425 139.6c34.9 13.7 59.6 53.1 53.8 89.5-5.7 36.5-41.8 70.1-50 118.2-8.1 48.2 11.8 110.9-5 151.1s-70.3 57.9-118.4 50.5-90.8-40-118.1-76.8-39.1-77.9-33.6-112.4c5.5-34.6 28.4-62.6 42.3-89.8s19-53.6 34.9-73.4c15.8-19.9 42.5-33.2 78.3-45.9 35.9-12.6 80.9-24.6 115.8-11z"
        fill="currentColor"
      />
    </svg>
  )
}
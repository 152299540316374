import React from 'react'

export default function({className = ''}) {
  return (
    <svg width="600" height="600" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="m382 201.7c47.8 40.6 126.2 44.2 147.6 71.7 21.5 27.6-14 79.1-56.7 101.5-42.7 22.3-92.6 15.5-135.1 32.6-42.6 17.2-77.8 58.2-101.1 54.8-23.2-3.5-34.5-51.4-46-90.1-11.4-38.7-22.9-68.1-30.8-109.6-7.8-41.6-12-95.2 12-139.9 24-44.8 76-80.7 110.6-59.9s51.8 98.3 99.5 138.9z"
        fill="currentColor"
      />
    </svg>
  )
}
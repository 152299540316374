import React, { useState, useEffect } from 'react'
import { useClasses } from '../lib/ReactUtils'

/* https://www.tailwindtoolbox.com/components/alerts */
const instance = {}

const COLORS = {
  success: 'green-600',
  error: 'red-600',
  info: 'blue-600',
}

export function Toast() {
  const [toast, setToast] = useState(null)
  const [type, setType] = useState('info')
  const [hidden, setHidden] = useState(true)
  const root = useClasses()

  useEffect(() => {
    let timer
    instance.show = (value, type = 'info', delay = 5000) => {
      if (value) setHidden(false)
      setToast(value)
      setType(type)
      if (delay > 0) timer = setTimeout(setToast, delay)
    }
    instance.hide = () => setToast(null)
    return () => clearTimeout(timer)
  }, [])

  return (
    <div ref={root} className={"fixed bottom-0 right-0 m-8 w-5/6 md:w-full max-w-sm cursor-pointer z-100 " + (hidden ? "hidden" : (toast ? "fade-in-right" : "fade-out-right"))} onClick={() => setToast(null)}>
      <div className={"cursor-pointer leading-tight tracking-snug flex items-start justify-between w-full bg-" + COLORS[type] + " h-17 opacity-95 rounded shadow-lg text-white p-3"}>
        {toast}
        <svg className="text-white fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
          <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
        </svg>
      </div>
    </div>
  )
}

export function useToast() {
  return instance
}

if (typeof window !== 'undefined') window.toast = instance

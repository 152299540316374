import { useRef, useState, useLayoutEffect, useEffect } from 'react'
import { useLocation } from '@reach/router'
import { processClasses } from '/src/vendors/runcss'
import { forEach } from 'lodash'

export { addCss, addClass, processClasses, configure } from '/src/vendors/runcss'


export const useClasses = (ref) => {
  ref = ref || useRef()
  useLayoutEffect(() => {
    processRef(ref)
  })
  return ref
}

export const processRef = (ref) => {
  const parent = typeof ref == 'string' ? document.querySelector(ref) : ref.current
  if (parent) {
    if (parent.className) processClasses(parent.className)
    parent.querySelectorAll('*[class]').forEach(function(element) {
      if (element.className) processClasses(element.className)
    })
  }
}

export const clearForm = function(form) {
  forEach(form, function(element) {
    if (element) element.value = ''
  })
}

export const useQueryParam = function(name, defaultValue) {
  const [value, setValue] = useState(defaultValue)
  const { href } = useLocation()
  useEffect(() => {
    if (href) {
      const params = getQueryParams(href)
      if (params[name]) setValue(params[name])
    }
  }, [href])
  return [value, setValue]
}


export const getQueryParams = function(url) {
  const [base, _query] = url.split('?')
  const [query, hash] = _query ? _query.split('#') : [ '', null ]
  return query
    .split('&')
    .reduce((params, param) => {
      const [key, value] = param.split('=')
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : ''
      return params
    }, {_:base,'#':hash})
}

export const usePrevious = function(value) {
  const ref = useRef()
  useEffect(() => { ref.current = value }, [value])
  // Return previous value (happens before update in useEffect above)
  return ref.current
}

export function useOnClickAway(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) return
        handler(event)
      }
      document.addEventListener("mousedown", listener)
      document.addEventListener("touchstart", listener)
      return () => {
        document.removeEventListener("mousedown", listener)
        document.removeEventListener("touchstart", listener)
      }
    },
    [ref, handler]
  )
}
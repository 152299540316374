import React from 'react'
import { Link } from 'gatsby'
import { useClasses } from '../lib/ReactUtils'
import { random } from '../lib/Utils'
import Blob2 from '../svg/Blob2'
import Blob3 from '../svg/Blob3'

import NewsLetter from '../components/NewsletterForm'
// import { openChat } from '../lib/Chat'

export default function Footer({className = ''}) {
  const root = useClasses()
  return (
    <div className={"relative w-full px-2 py-6 font-light overflow-hidden " + className} id="footer" ref={root}>
      <Blob2 className={'w-80 pointer-events-none w-80 absolute text-white opacity-5 -right-' + random(12) + ' -top-' + random(7)}/>       
      <Blob3 className={'w-100 pointer-events-none w-100 absolute text-white opacity-8 -left-' + random(25) + ' -bottom-' + random(40)}/>  
      <div className="container mx-auto">
        <div className="flex flex-col sm:flex-row mb-4">
          <div className="sm:w-1/4 h-auto">
            <ul className="list-reset leading-normal">
              <li><Link to="/" className="footer">Home</Link></li>
              <li><Link to="/plans" className="footer">Plans</Link></li>
              <li><Link to="/about" className="footer">About Us</Link></li>
              <li><Link to="/contact" className="footer">Contact Us</Link></li>
              <li><Link to="/features" className="footer">Features</Link></li>
              <li><a href="https://docs.oneoffice.ca" className="footer">Main Docs</a></li>
              <li><a href="https://erpdocs.oneoffice.ca" className="footer">ERP Docs</a></li>
              <li><a href="https://editordocs.oneoffice.ca" className="footer">Editor Docs</a></li>
              <li><Link to="/faqs" className="footer">FAQs</Link></li>
              <li><Link to="/download" className="footer">Downloads</Link></li>
              <li><Link to="/blog" className="footer ">Blog</Link></li>
              <li><Link to="/privacy" className="footer">Privacy</Link></li>
              <li><Link to="/terms" className="footer">Terms of Service</Link></li>
              <li><Link to="/sla" className="footer">SLA</Link></li>
            </ul>
          </div>
          <div className="sm:w-1/4 h-auto">
            <ul className="list-reset leading-normal">
              <li><Link to="/buy" className="basic font-bold text-xl text-orange-500 hover:text-orange-800 transition duration-300">BUY</Link></li>
              <li><Link to="/demo" className="basic font-bold text-xl text-sky-500 hover:text-sky-900 transition duration-300">DEMO</Link></li>
              <li><Link to="/trial" className="basic font-bold text-xl text-cyan-700 hover:text-cyan-900 transition duration-300">TRIAL</Link></li>
              <li><Link to="/savings" className="footer font-bold">Savings</Link></li>
              <li><Link to="/erp" className="footer">ERP</Link></li>
              <li><Link to="/migration" className="footer">Migration</Link></li>
              <li><Link to="/training" className="footer">Training</Link></li>
              <li><Link to="/compare" className="footer">Compare</Link></li>
              <li><Link to="/technology" className="footer">Technology</Link></li>
              <li><Link to="/security" className="footer">Security</Link></li>
              <li><Link to="/hybrid" className="footer">Hybrid</Link></li>
              <li><Link to="/backup" className="footer">Backup & Recovery</Link></li>
            </ul>
          </div>
          <div className="sm:w-1/2 sm:mt-0 mt-8 h-auto flex flex-col justify-between" id='newsletter'>
            <NewsLetter/>
            <div className='mt-5'>
              <div className='text-sm md:text-base text-gray-200'>&copy; 2022 ZeGenie Inc. - All rights reserved</div>
              <div className='text-xs md:text-sm lg:text-base text-gray-500'>1155 René-Levesqe O #2500, Montreal, H3B 3X7, Canada</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import { addCss } from '../lib/ReactUtils'

// autocomplete styling is a pain see here https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete
// we can only support a small subset of input styline

addCss(`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:active,
  input:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:active,
  select:-webkit-autofill:focus
  {
    -webkit-box-shadow: 0 0 0 1000px white inset;
    -webkit-text-fill-color: #2d3748;
    @apply text-gray-800;
  }

  input.bg-primary:-webkit-autofill,
  input.bg-primary:-webkit-autofill:hover,
  input.bg-primary:-webkit-autofill:active,
  input.bg-primary:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #009cfd inset; 
  }

  input.bg-primary-dark:-webkit-autofill,
  input.bg-primary-dark:-webkit-autofill:hover,
  input.bg-primary-dark:-webkit-autofill:active,
  input.bg-primary-dark:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #073e60 inset; 
  }

  input.text-white:-webkit-autofill,
  input.text-white:-webkit-autofill:hover,
  input.text-white:-webkit-autofill:active,
  input.text-white:-webkit-autofill:focus {
    -webkit-text-fill-color: white;
    @apply text-white;
  }    

  form:not(.basic) {
    @apply w-full flex flex-col gap-2
  }

  form:not(.basic) > div {
    @apply relative
  }

  select,
  input,
  textarea
  {
    @apply appearance-none outline-none focus:outline-none
  }

  select:not(.basic) {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%2371717a' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    padding-right: 2.5rem;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    padding-top: 0.5rem;
    padding-right: 0.75rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    font-size: 1rem;
    line-height: 1.5rem;
    @apply w-full text-gray-800 bg-transparent max-h-10 border rounded-md border-gray-400
  }

  input[type="text"]:not(.basic),
  input[type="email"]:not(.basic),
  input[type="password"]
  {
    padding-top: 0.5rem;
    padding-right: 0.75rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    font-size: 1rem;
    line-height: 1.5rem;
    @apply block mb-1 w-full bg-transparent text-gray-800 appearance-none outline-none focus:outline-none max-h-10 border rounded-md border-gray-400
  }

  input[type='checkbox']:not(.basic):checked, input[type='radio']:not(.basic):checked {
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }

  input[type='checkbox']:not(.basic), input[type='radio']:not(.basic) {
    padding: 0;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    vertical-align: middle;
    background-origin: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    flex-shrink: 0;
    @apply inline-block p-0 w-4 h-4 text-primary-dark appearance-none outline-none focus:outline-none rounded mr-2 bg-white cursor-pointer border-1 border-primary-dark
  }

  input[type='checkbox']:not(.basic) {
    border-radius: 3px;
  }

  input[type='radio']:not(.basic) {
    border-radius: 100%;
  }

  input[type='checkbox']:not(.basic):checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  }

  input[type='radio']:not(.basic):checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
  }

  input[type='checkbox']:not(.basic) ~ label, input[type='radio']:not(.basic) ~ label {
    @apply text-lg font-thin text-gray-800 align-middle cursor-pointer;
  }

  input.bg-white {
    background-color: white !important;
  }

  textarea:not(.basic)
  {
    padding-top: 0.5rem;
    padding-right: 0.75rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    font-size: 1rem;
    line-height: 1.5rem;
    @apply block w-full text-gray-800 appearance-none outline-none focus:outline-none bg-transparent border rounded-md border-gray-400
  }

  input[type="text"]:not(.basic) ~ label,
  input[type="password"]:not(.basic) ~ label,
  input[type="email"]:not(.basic) ~ label,
  textarea:not(.basic) ~ label
  {
    padding-top: 0.6rem;
    @apply absolute top-0 left-0 text-sm bg-transparent px-4 duration-300 origin-0 -translate-y-6 text-gray-500 font-light pointer-events-none
  }

  input[type="text"]:not(.basic):focus-within ~ label,
  input[type="text"]:not(.basic):not(:placeholder-shown) ~ label,
  input[type="password"]:not(.basic):focus-within ~ label,
  input[type="password"]:not(.basic):not(:placeholder-shown) ~ label,
  input[type="email"]:not(.basic):focus-within ~ label,
  input[type="email"]:not(.basic):not(:placeholder-shown) ~ label,
  textarea:not(.basic):focus-within ~ label,
  textarea:not(.basic):not(:placeholder-shown) ~ label
  {
    @apply transform scale-80 -translate-y-4 py-0 px-1 my-1 bg-white;
  }

  input[type="text"].basic ~ label,
  input[type="password"].basic ~ label,
  input[type="email"].basic ~ label,
  textarea.basic ~ label
  {
    padding-top: 0.7rem;
    @apply absolute top-0 left-0 text-sm transition bg-transparent px-4 duration-200 origin-0 -translate-y-6 pointer-events-none
  }

  input[type="text"].basic:focus-within ~ label,
  input[type="text"].basic:not(:placeholder-shown) ~ label,
  input[type="password"].basic:focus-within ~ label,
  input[type="password"].basic:not(:placeholder-shown) ~ label,
  input[type="email"].basic:focus-within ~ label,
  input[type="email"].basic:not(:placeholder-shown) ~ label,
  textarea.basic:focus-within ~ label,
  textarea.basic:not(:placeholder-shown) ~ label
  {
    @apply opacity-0
  }

  select:not(.basic) ~ label
  {
    @apply absolute top-0 left-0 text-sm bg-white transform scale-80 -translate-y-4 z-0 py-0 px-1 my-1 text-gray-500
  }
`);
import { useEffect, useState } from 'react'

const URL = 'https://ipapi.co/json/'

const instance = {
  country: 'US',
  country_code: 'US',
  partner: 'coporate'
}

let setLocations = [ ]

export function GeoLocationComponent() {
  useEffect(() => {
    var xhr = new XMLHttpRequest()
    xhr.onreadystatechange = function() {
        if (xhr.readyState == XMLHttpRequest.DONE) {
            try {
              const json = JSON.parse(xhr.responseText)
              instance.country_code = json.country_code_iso3
              instance.country = json.country_name
              instance.city = json.city
              instance.region = json.region
              instance.partner = getPartner(json)
              setLocations.forEach((f) => f(json.country_name))
              // console.log(instance)
            }
            catch(e) {
              console.error('Geolocation failed', e)
            }
        }
    }
    xhr.open('GET', URL, true)
    xhr.send(null)
  }, [])

  return null
}

function getPartner(json) {
  if (json.continent_code == 'NA') return 'corporate'
  if (json.continent_code == 'SA') return 'latin-america'
  const country = json.country_code_iso3
  if (['EGY'].includes(country)) return 'egypt'
  if (['SAU'].includes(country)) return 'ksa'
  if (['DZA','BHR','DJI','EGY','IRQ','IRN','JOR','KWT','LBN','LBY','MAR','MRT','OMN','PSE','QAT','SAU','SSD','SDN','SYR','TUN','ARE','TUR','YEM','ESH'].includes(country)) return 'middle-east'
  if (json.continent_code == 'AF') return 'south-africa'
  return 'corporate'
}


export default instance

export const useLocation = function() {
  const [, setLocation] = useState(1)
  useEffect(() => {
    setLocations.push(setLocation)
    return () => {
      const index = setLocations.indexOf(setLocation)
      if (index != -1) setLocations.splice(setLocation)
    }
  }, [setLocation])
  return instance
}


import { useClasses, useOnClickAway } from '../lib/ReactUtils'
import React, { useState, useRef, useCallback } from 'react'
import { Link } from "gatsby"
import Logo from '../svg/Logo'

export default function Menu({dark = false, className = ''}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const menu = useRef()
  useOnClickAway(menu, useCallback(() => {
    if (isMenuOpen) closeMenu()
  }))
  const hasMenuOpened = useRef(false)
  const root = useClasses()
  const menuClass = dark ? 'menu menu-dark' : 'menu'

  const closeMenu = () => {
    setIsMenuOpen(false)
    hasMenuOpened.current = true
  }

  const toggleMenu = () => {
    if (isMenuOpen) closeMenu()
    else setIsMenuOpen(true)
  }

  return (
    <header className={"h-20 flex items-center absolute top-0 w-full z-100 " + (dark ? 'bg-black/30 ': '') + className} id="menu" ref={root}>
      <div className="container px-2 flex items-center justify-between gap-4">
        <Link to="/" className='w-1/4 lg:w-1/5 basic ml-1'>
          <Logo className={'duration-300 transition-color hover:text-primary w-40 sm:w-60 md:w-full ' + (dark ? 'text-white' : 'text-gray-700')}/>
        </Link>
        <div className="flex items-center">
          <nav className="uppercase text-base xl:text-lg hidden lg:flex items-center font-light">
            <Link to="/features" className={menuClass} activeClassName='active'>Features</Link>
            <Link to="/migration" className={menuClass} activeClassName='active'>Migration</Link>
            <Link to="/erp" className={menuClass} activeClassName='active'>ERP</Link>
            <Link to="/plans" className={menuClass} activeClassName='active'>Plans</Link>
            <Link to="/compare" className={menuClass} activeClassName='active'>Compare</Link>
            <a href="https://docs.oneoffice.ca" className={menuClass}>Docs</a>
            <Link to="/blog" className={menuClass} activeClassName='active'>Blog</Link>
            <Link to="/demo" className='menu font-bold text-sky-500 hover:text-sky-800' activeClassName='border-sky-500'>DEMO</Link>
            <Link to="/trial" className='menu font-bold text-cyan-400 hover:text-cyan-800' activeClassName='border-cyan-400'>TRIAL</Link>
            <Link to="/buy" className={'ml-3 basic rounded transform duration-300 ' + (dark ? 'text-white hover:text-orange-400' : 'text-orange-500 hover:text-orange-700')}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" viewBox="0 0 20 20" fill="currentColor">
                <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
              </svg>
            </Link>            
          </nav>
          <button className={"lg:hidden group flex flex-col ml-4 mr-2 " + (dark ? ' dark' : '')} onClick={toggleMenu}>
            <span className="w-6 h-1 bg-gray-800 dark:bg-white mb-1 group-hover:bg-primary"/>
            <span className="w-6 h-1 bg-gray-800 dark:bg-white mb-1 group-hover:bg-primary"/>
            <span className="w-6 h-1 bg-gray-800 dark:bg-white mb-1 group-hover:bg-primary"/>
          </button>
          <div className={"lg:hidden absolute top-0 left-0 w-full z-10 py-6 pl-5 bg-white/85 font-bold text-gray-700 transform transition duration-200 " + (isMenuOpen ? 'translate-y-0' : '-translate-y-full')} ref={menu}>
            <nav className='text-gray-700 text-lg flex flex-col' onClick={closeMenu}>
              <Link to="/features" className='menu' activeClassName='active'>Features</Link>
              <Link to="/migration" className='menu' activeClassName='active'>Migration</Link>
              <Link to="/erp" className='menu' activeClassName='active'>ERP</Link>
              <Link to="/plans" className='menu' activeClassName='active'>Plans</Link>
              <Link to="/compare" className='menu' activeClassName='active'>Compare</Link>
              <a href="https://docs.oneoffice.ca" className='menu'>Docs</a>
              <Link to="/blog" className='menu' activeClassName='active'>Blog</Link>
              <Link to="/demo" className='menu text-sky-500 hover:text-sky-800' activeClassName='border-sky-500'>DEMO</Link>
              <Link to="/trial" className='menu text-cyan-400 hover:text-cyan-800' activeClassName='border-cyan-400'>TRIAL</Link>
              <Link to="/buy" className='menu text-orange-500 hover:text-orange-800' activeClassName='border-orange-500'>BUY</Link> 
            </nav>
          </div>
        </div>
      </div>
    </header>
  )
}
// https://github.com/mui-org/material-ui/blob/4f2a07e140c954b478a6670c009c23a59ec3e2d4/docs/src/pages/components/popper/ScrollPlayground.js
import React, { useState, useEffect, createRef, useImperativeHandle, useCallback } from 'react'
import Popper from '../lib/Popper'
import { processClasses, useClasses, useOnClickAway } from '../lib/ReactUtils'

// otherwise we get flickering at first show
processClasses('transition-opacity opacity-0')
processClasses('opacity-100')

const ref = createRef()

export default function PopupController() {
  this.isActive = false
  var timer

  this.show = (parameters) => {
    if (this.isActive) {
      this.hide()
      return setTimeout(() => this.show(parameters), 100)
    }
    if (timer) {
      clearTimeout(timer)
      timer = null
    }
    if (ref.current) {
      ref.current.setParameters(parameters)
      ref.current.setOpen(true)
    }
    this.isActive = true
  }

  this.close = this.hide = () => {
    if (!this.isActive) return
    if (ref.current.setOpen) ref.current.setOpen(false)
    if (ref.current.onClose()) ref.current.onClose().call()
    timer = setTimeout(function() {
      ref.current.setParameters({})
      timer = null
    }, 500)
    this.isActive = false
  }
}


export function PopupComponent() {
  const [open, setOpen] = useState(false)
  const [parameters, setParameters] = useState({})
  const [animationClass, setAnimationClass] = useState('duration-10 opacity-0')
  const { component:Component, text, context, data = { }, position = 'bottom', offset = 5, keepOpen, className = 'p-3', onClose } = parameters
  const root = useClasses()
  useOnClickAway(root, useCallback(() => !keepOpen && PopupController.close()))

  useImperativeHandle(ref, () => ({
    setOpen,
    setParameters,
    onClose: () => onClose
  }), [onClose])

  useEffect(() => {
    if (open) {
      setAnimationClass('duration-10 opacity-0')
      setTimeout(() => setAnimationClass('duration-50 opacity-100'), 100)
    }
    else setAnimationClass('duration-10 opacity-0')
  }, [open])

  if (!context) return ''

  return (
    <Popper
      ref={root}
      placement={position}
      anchorEl={context}
      open={open}
      className={'popup z-2 shadow transition-opacity ' + animationClass}
      offset={offset}
    >
      { keepOpen ?
        <div className='z-2 absolute right-0 top-0' onClick={PopupController.close}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div> : ''}
      <div className={'shadow rounded relative overflow-hidden ' + className}>
        { Component ? <Component {...data}/> : text }
      </div>
    </Popper>
  )
}


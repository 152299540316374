import React from 'react'

export default function({className = ''}) {
  return (
    <svg version="1.1" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        fill="currentColor"
        d="m434.6 173.9c36.5 28 60.4 77.1 48.1 113.7-12.4 36.7-61.1 61-97.6 97s-60.8 83.7-107 105.6-114.3 18-159.2-18c-44.8-36-66.4-104.1-48.6-154.4s75-82.9 119.8-110.9 77.4-51.4 118.1-59.7c40.8-8.2 89.9-1.3 126.4 26.7z"
      />
    </svg>
  )
}